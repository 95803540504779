.no-border {
	border: 0;
}
.br-4 {
	border-radius: 4px;
}
.m-1 {
	margin: var(--oc-spacing);
}
.m-2 {
	margin: var(--oc-spacing2);
}
.mx-0 {
	margin-left: 0;
	margin-right: 0;
}
.mx-1 {
	margin-left: var(--oc-spacing);
	margin-right: var(--oc-spacing);
}
.mx-2 {
	margin-left: var(--oc-spacing2);
	margin-right: var(--oc-spacing2);
}
.my-1 {
	margin-bottom: var(--oc-spacing);
	margin-top: var(--oc-spacing);
}
.my-2 {
	margin-bottom: var(--oc-spacing2);
	margin-top: var(--oc-spacing2);
}
.my-3 {
	margin-bottom: var(--oc-spacing3);
	margin-top: var(--oc-spacing3);
}
.my-4 {
	margin-bottom: var(--oc-spacing4);
	margin-top: var(--oc-spacing4);
}
.ml-1 {
	margin-left: var(--oc-spacing);
}
.ml-2 {
	margin-left: var(--oc-spacing2);
}
.mr-1 {
	margin-right: var(--oc-spacing);
}
.mr-2 {
	margin-right: var(--oc-spacing2);
}
.mr-3 {
	margin-right: var(--oc-spacing3);
}
.mb-1 {
	margin-bottom: var(--oc-spacing);
}
.mb-2 {
	margin-bottom: var(--oc-spacing2);
}
.mb-3 {
	margin-bottom: var(--oc-spacing3);
}
.mb-4 {
	margin-bottom: var(--oc-spacing4);
}
.mb-5 {
	margin-bottom: var(--oc-spacing5);
}
.mb-6 {
	margin-bottom: var(--oc-spacing6);
}
.mt-2px {
	margin-top: 2px;
}
.mt-1 {
	margin-top: var(--oc-spacing);
}
.mt-2 {
	margin-top: var(--oc-spacing2);
}
.mt-3 {
	margin-top: var(--oc-spacing3);
}
.mt-4 {
	margin-top: var(--oc-spacing4);
}
.mt-5 {
	margin-top: var(--oc-spacing5);
}
.p-1 {
	padding: var(--oc-spacing);
}
.p-2 {
	padding: var(--oc-spacing2);
}
.p-3 {
	padding: var(--oc-spacing3);
}
.p-4 {
	padding: var(--oc-spacing4);
}
.px-1 {
	padding-left: var(--oc-spacing);
	padding-right: var(--oc-spacing);
}
.px-2 {
	padding-left: var(--oc-spacing2);
	padding-right: var(--oc-spacing2);
}
.px-3 {
	padding-left: var(--oc-spacing3);
	padding-right: var(--oc-spacing3);
}
.px-4 {
	padding-left: var(--oc-spacing4);
	padding-right: var(--oc-spacing4);
}
.px-5 {
	padding-left: var(--oc-spacing5);
	padding-right: var(--oc-spacing5);
}
.py-1 {
	padding-bottom: var(--oc-spacing);
	padding-top: var(--oc-spacing);
}
.py-2 {
	padding-bottom: var(--oc-spacing2);
	padding-top: var(--oc-spacing2);
}
.py-3 {
	padding-bottom: var(--oc-spacing3);
	padding-top: var(--oc-spacing3);
}
.pl-0 {
	padding-left: 0;
}
.pl-1 {
	padding-left: var(--oc-spacing);
}
.pl-2 {
	padding-left: var(--oc-spacing2);
}
.pl-3 {
	padding-left: var(--oc-spacing3);
}
.pl-4 {
	padding-left: var(--oc-spacing4);
}
.pl-5 {
	padding-left: var(--oc-spacing5);
}
.pr-1 {
	padding-right: var(--oc-spacing);
}
.pr-2 {
	padding-right: var(--oc-spacing2);
}
.pr-3 {
	padding-right: var(--oc-spacing3);
}
.pr-4 {
	padding-right: var(--oc-spacing4);
}
.pr-5 {
	padding-right: var(--oc-spacing5);
}
.pb-1 {
	padding-bottom: var(--oc-spacing);
}
.pb-2 {
	padding-bottom: var(--oc-spacing2);
}
.pb-3 {
	padding-bottom: var(--oc-spacing3);
}
.pb-4 {
	padding-bottom: var(--oc-spacing4);
}
.pt-0 {
	padding-top: 0;
}
.pt-1 {
	padding-top: var(--oc-spacing);
}
.pt-2 {
	padding-top: var(--oc-spacing2);
}
.pt-3 {
	padding-top: var(--oc-spacing3);
}
.pt-4 {
	padding-top: var(--oc-spacing4);
}
.pt-5 {
	padding-top: var(--oc-spacing5);
}
.fw-8,
.extra-bold {
	font-weight: 800;
}
.fw-7,
.bold {
	font-weight: 700;
}
.fw-6 {
	font-weight: 600;
}
.fw-5 {
	font-weight: 500;
}
.fw-4 {
	font-weight: 400;
}
.fw-3 {
	font-weight: 300;
}
.fw-2 {
	font-weight: 200;
}
.ta-r {
	text-align: right;
}
.ta-l {
	text-align: left;
}
.ta-c {
	text-align: center;
}
.capitalize {
	text-transform: capitalize;
}

.fs-10,
.text-xs {
	font-size: 10px;
}
.fs-12,
.text-sm {
	font-size: 12px;
}
.fs-14,
.text-med {
	font-size: 14px;
}
.fs-20 {
	font-size: 20px;
}
.fs-24 {
	font-size: 24px;
}
.fs-32 {
	font-size: 32px;
}
.fs-48 {
	font-size: 48px;
}
.no-wrap {
	text-wrap: nowrap;
}
.lh-init {
	line-height: initial;
}

/* layout */
.full-w {
	width: 100%;
}
.inline {
	display: inline;
}
.block {
	display: block;
}

.flex-row,
.flex-col {
	display: flex;
	align-items: flex-start;
	gap: 0;

	&.spaceBetween {
		justify-content: space-between;
	}

	&.flex-end {
		justify-content: flex-end;
	}

	&.centered {
		justify-content: center;
		align-items: center;
	}
}
.flex-col {
	flex-direction: column;
}
.flex-1 {
	flex: 1;
}
.flex-2 {
	flex: 2;
}
.flex-3 {
	flex: 3;
}
.flex-0 {
	flex: 0;
}
.as-fe {
	align-self: flex-end;
}
.as-fs {
	align-self: flex-start;
}
.as-c {
	align-self: center;
}
.as-s {
	align-self: stretch;
}
.ai-c {
	align-items: center;
}
.ai-fe {
	align-items: flex-end;
}
.ai-s {
	align-items: stretch;
}
.jc-fe {
	justify-content: flex-end;
}
.jc-c {
	justify-content: center;
}
.gap-1 {
	gap: var(--oc-spacing);
}
.gap-2 {
	gap: var(--oc-spacing2);
}
.gap-3 {
	gap: var(--oc-spacing3);
}
.wb-a {
	word-break: break-all;
}
